import axios from "axios";
import {ReactElement, useMemo} from "react";
import {useAuthContext} from "../../shared/contexts/AuthContext";

export const getApiUrl = () => {
  switch (import.meta.env.MODE) {
    case "test":
      return "https://test.api.jasperdental.fr";

    case "staging":
      return "https://staging.api.jasperdental.fr";

    case "production":
      return "https://api.jasperdental.fr";

    default:
      // TO UNCOMMENT WHEN WE WANT TO TEST WEBAPP IN ANOTHER DEVICE
      // return (window.location.hostname === 'localhost')? "http://localhost:5050" : "http://10.7.56.205:5050";
      // THE IP IS TO BE CHANGED ALSO DEPENDING ON YOUR DEVICE
      return "http://localhost:5050";
  }
};

export const getWebsocketApiUrl = () => {
  switch (import.meta.env.MODE) {
    case "staging":
      return "https://websocket.staging.jasperdental.fr";
    case "production":
      return "https://websocket.jasperdental.fr";
    default:
      // TO UNCOMMENT WHEN WE WANT TO TEST WEBAPP IN ANOTHER DEVICE
      // return (window.location.hostname === 'localhost')? "http://localhost:5051" : "http://10.7.56.205:5051";
      // THE IP IS TO BE CHANGED ALSO DEPENDING ON YOUR DEVICE
      return "http://localhost:5051";
  }
};

const API_URL = getApiUrl();

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const refreshApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

type ApiInterceptorsProps = {
  children: ReactElement;
};

export const ApiInterceptors = ({children}: ApiInterceptorsProps) => {
  const {logOut} = useAuthContext();
  useMemo(() => {
    api.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        const originalRequest = error.config;
        if (
          originalRequest.url !== "/auth/login" &&
          originalRequest.url !== "/auth/refresh-token" &&
          originalRequest.url !== "/auth/refresh-token-desktop" &&
          error.response &&
          error.response.status === 401
        ) {
          return refreshApi
            .get("auth/refresh-token")
            .then(async res => {
              if (res.status === 200) {
                // storeRefreshTokenOnDesktop();
                const responseData = await api(originalRequest);
                return responseData.data;
              }
            })
            .catch(async err => {
              console.error(err);
              await logOut();
            });
        }
        return Promise.reject(error);
      },
    );
  }, []);

  return children;
};

export {api, API_URL};
