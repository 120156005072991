import {Loader, LoadingOverlay} from "@mantine/core";
import React, {FC, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {useCreateOrder} from "./useCreateOrder";
import {useIpcContext} from "/@/shared/contexts/IpcContext";
import {useLocation} from "react-router-dom";
import {CreateProductPage} from "/@/pages/products/create/CreateProductPage";
import {CreateAnatomyItemStep, CreateOrderStep} from "/@/shared/types/orders/createOrder.types";
import {CreateOrderPatientInfo} from "/@/pages/orders/create/CreateOrderPatientInfo";
import {CreateOrderReview} from "../CreateOrderReview";
import {useCreateOrderStore} from "/@/shared/stores/orders/create-store.store";
import {
  ItemType,
  GetOrderEstimatedDeliveryDateDocument,
} from "/@/generated/graphql";
import { useAuthContext } from "/@/shared/contexts/AuthContext";
import {
  isBridgeAndInlayCoreOverlapping,
  UPDATE_ORDER,
  CREATE_REMOVABLE_ITEM,
  DELETE_MANY_REMOVABLE_ITEMS,
  UPDATE_PRODUCT,
  UPDATE_DELIVERY_DATE,
  UpperArchTeeth,
  LowerArchTeeth,
  ArchTypeEn,
  OrderStatus,
} from "@jasper/shared";
import {
  useMutation,
  useLazyQuery,
} from "@apollo/client";

export const CreateOrderPage: FC = () => {
  const navigate = useNavigate();
  const {state} = useLocation();
  const {existingDraftOrderLoading, order, refetchOrder} = useCreateOrder(
    state ? {newOrderId: state.id} : undefined,
  );
  const {user} = useAuthContext();
  const [updateOrder] = useMutation(UPDATE_ORDER);
  const [updateProduct] = useMutation(UPDATE_PRODUCT);
  const [createRemovableItem] = useMutation(CREATE_REMOVABLE_ITEM);
  const [deleteManyRemovableItem] = useMutation(DELETE_MANY_REMOVABLE_ITEMS);
  const [updateDeliveryDate] = useMutation(UPDATE_DELIVERY_DATE);
  const {creatingOrderId, setCreatingOrderId} = useIpcContext();
  const [
    newOrderId,
    createOrderStep,
    createProductStep,
    createProductIndex,
    updateNewOrderId,
    updateCreateOrderStep,
    updateCreateProductIndex,
    updateCreateProductStep,
  ] = useCreateOrderStore(state => [
    state.newOrderId,
    state.createOrderStep,
    state.createProductStep,
    state.createProductIndex,
    state.updateNewOrderId,
    state.updateCreateOrderStep,
    state.updateCreateProductIndex,
    state.updateCreateProductStep,
  ]);

  useEffect(() => {
    if (creatingOrderId) {
      setCreatingOrderId(null);
    }
  }, []);

  const [getDeliveryDate] = useLazyQuery(GetOrderEstimatedDeliveryDateDocument);

  const getCommentBlock = (order, orderComment)=>{
    const existingOrderComment = (order?.orderComment ?? []).find((comment) => comment.type === CommentType.ORDER);
    let commentBlock = undefined;
    if (existingOrderComment) {
      commentBlock = {
        update: [{
          data: {
            comment: {
              set: orderComment,
            },
          },
          where: {
            id: existingOrderComment?.id,
          },
        }],
      };
    }
    else if (orderComment) {
      commentBlock = {
        create: [{
          comment: orderComment,
        }],
      };
    }
    return commentBlock;
  };

  useEffect(()=>{
    if (
      order?.products &&
      order?.products.filter(
      value =>
      value.productType === ItemType.Bridge ||
      value.productType === ItemType.InlayCore,
    ).length === 2
      && isBridgeAndInlayCoreOverlapping(order?.products)
      && createProductIndex === 1
      && createProductStep === CreateAnatomyItemStep.Preferences
    ) {
      if (order?.products?.length === 2) {
        updateCreateOrderStep(CreateOrderStep.Review);
        updateCreateProductStep(0);
      }
      else {
        updateCreateProductIndex(createProductIndex + 1);
        updateCreateProductStep(0);
      }
    }
  },[createProductStep]);

  useEffect(() => {
    if (
      order &&
      order.products &&
      createProductIndex > order?.products.length - 1
    ) {
      updateCreateOrderStep(CreateOrderStep.Review);
    } else {
      updateCreateProductStep(0);
    }
  }, [createProductIndex]);

  useEffect(() => {
    if (createOrderStep === CreateOrderStep.Review && order?.products) {
      updateCreateProductIndex(order?.products?.length - 1);
    }
  }, [createOrderStep]);

  useEffect(() => {
    if (newOrderId === null && order) {
      updateNewOrderId(order.id);
    } else if (order && order.id && newOrderId !== order.id) {
      updateCreateOrderStep(CreateOrderStep.Patient);
      updateCreateProductIndex(0);
      updateCreateProductStep(0);
      updateNewOrderId(order.id);
    }
  }, [order]);

  if (existingDraftOrderLoading) {
    return (
      <LoadingOverlay visible={existingDraftOrderLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} >
        <Loader />
      </LoadingOverlay>
    );
  }

  if (!order || !order.products) {
    return <div>ERROR gql</div>;
  }
  const numberOfProducts = order.products.length;
  if (numberOfProducts < 1) {
    return <div>{"Aucun produit à créer pour cette commande"}</div>;
  }

  if (createOrderStep === CreateOrderStep.Patient) {
    return <CreateOrderPatientInfo order={order} />;
  } else if (
    createOrderStep === CreateOrderStep.Product &&
    createProductIndex >= 0 &&
    createProductIndex < order.products.length
  ) {
    return (
      <CreateProductPage
        product={order.products[createProductIndex]}
        allProducts={order.products}
        orderCreationProductOrder={0}
        patient={order.patient}
        dentist={order.user}
        order={order}
        refetchOrder={refetchOrder}
        saveOrderComment={async(orderComment: string) => {
            try {
                await updateOrder({
                  variables: {
                    where: {
                      id: order.id,
                    },
                    data: {
                      comment: { set: orderComment ?? null },
                      orderComment: getCommentBlock(order, orderComment),
                    },
                  },
                });
            } catch (e) {
              console.error(e);
            }
        }}
        updateOrder={async(values) => {
          try {
            const product = order?.products[createProductIndex];
            await deleteManyRemovableItem({
              variables: {
                where: {
                  productId: {
                    equals: product?.id,
                  },
                },
              },
            });
            if (values.scanOnlyType === ItemType.RetentionWire){
              await updateProduct({
                variables: {
                  where: {
                    id: product?.id,
                  },
                  data: {
                    teeth: values?.archType === ArchTypeEn.UPPER_ARCH
                      ? UpperArchTeeth
                      : values?.archType === ArchTypeEn.LOWER_ARCH
                        ? LowerArchTeeth
                        : [...UppperArchTeeth, ...LowerArchTeeth],
                  },
                },
              });
              if (values?.archType === ArchTypeEn.UPPER_ARCH || values?.archType === ArchTypeEn.BOTH_ARCH){
                await createRemovableItem({
                  variables: {
                    args: {
                      itemType: values.scanOnlyType,
                      teeth: UpperArchTeeth,
                      teethToManufacture: {
                        set: UpperArchTeeth,
                      },
                      product: {
                        connect: {
                          id: product?.id,
                        },
                      },
                    },
                  },
                });
              }
              if (values?.archType === ArchTypeEn.LOWER_ARCH || values?.archType === ArchTypeEn.BOTH_ARCH){
                await createRemovableItem({
                  variables: {
                    args: {
                      itemType: values.scanOnlyType,
                      teeth: LowerArchTeeth,
                      teethToManufacture: {
                        set: UpperArchTeeth,
                      },
                      product: {
                        connect: {
                          id: product?.id,
                        },
                      },
                    },
                  },
                });
              }
            } else {
              await createRemovableItem({
                variables: {
                  args: {
                    itemType: values.scanOnlyType,
                    waxUpType: values.waxUpType,
                    product: {
                      connect: {
                        id: product?.id,
                      },
                    },
                  },
                },
              });
            }
            await refetchOrder();
          } catch (e) {
            console.error(e);
          }
        }}
        getDeliveryDate={getDeliveryDate}
        updateDeliveryDate={updateDeliveryDate}
        onSubmit={async(newDeliveryDateSelected, orderComment) => {
          try {
            // setIsLoading(true)
            const toSaveDeliveryDate = newDeliveryDateSelected ? new Date(newDeliveryDateSelected) : undefined;
            if (toSaveDeliveryDate)
              toSaveDeliveryDate.setHours(12);
            await updateOrder({
              variables: {
                where: {
                  id: order.id,
                },
                data: {
                  confirmedAt: {
                    set: Date.now(),
                  },
                  comment: { set: orderComment ?? null },
                  status: { set: OrderStatus.CONFIRMED },
                  // orderComment: getCommentBlock(),
                  // deliveryDate:
                  //   toSaveDeliveryDate
                  //   ? { set: toSaveDeliveryDate }
                  //   : getOrderEstimatedDeliveryDate?.getOrderEstimatedDeliveryDate?.deliveryDate
                  //     ? {
                  //       set: getOrderEstimatedDeliveryDate?.getOrderEstimatedDeliveryDate?.deliveryDate,
                  //     }
                  //     : undefined,
                  // lastShippingDate:
                  //   toSaveDeliveryDate
                  //   ? { set: getLastShippingDateFromDeliveryDate(toSaveDeliveryDate) }
                  //   : getOrderEstimatedDeliveryDate?.getOrderEstimatedDeliveryDate
                  //     ? {
                  //       set: getLastShippingDateFromDeliveryDate(getOrderEstimatedDeliveryDate?.getOrderEstimatedDeliveryDate?.deliveryDate),
                  //     }
                  //     : undefined,
                  orderBilling: {
                    create: [
                      {
                        user: {
                          connect: {
                            id: order.user.id,
                          },
                        },
                      },
                    ],
                  },
                },
              },
            });
            // if (res.data?.updateOrder?.deliveryDate) {
            //   const estimatedDeliveryDate = new Date(
            //     res.data.updateOrder.deliveryDate,
            //   );
            //   setEstimatedDeliveryDateString(
            //     estimatedDeliveryDate.toLocaleString("fr-FR", {
            //       year: "numeric",
            //       month: "long",
            //       day: "numeric",
            //       weekday: "long",
            //     }),
            //   );
            // }
            // setIsShowConfirmModal(true);
            // if (checkHasGenericImplant(order)){
            //   await updateDeliveryDate({
            //     variables: {
            //       orderId: order.id,
            //       newDeliveryDate: toSaveDeliveryDate,
            //       shouldUpdateAbutmentType: true,
            //     },
            //   });
            // }
            // await notifyPlacedOrder({
            //   variables: {
            //     orderId: order.id,
            //   },
            // });
            // setIsLoading(false)
            navigate(`/order/${order.id}`);
          } catch (e) {
            console.error(e);
            // setIsLoading(false)
          }
        }}
        user={user}
      />
    );
  } else if (createOrderStep === CreateOrderStep.Review) {
    return (
      <CreateOrderReview
        order={order}
        refetchOrder={refetchOrder}
        // setCreateOrderStep={setCreateOrderStep}
      />
    );
  }
  return (
    <div>
      {
        "Une erreur s'est produite lors de la création de votre commande. Veuillez contacter l'équipe Jasper."
      }
    </div>
  );
};
