import React, { FC, useEffect, useRef, useState } from "react";
import {
  CommentType,
  GetOrderByUniqueAttributeQuery,
  SendOrderHelpRequestDocument,
} from "/@/generated/graphql";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Button, Flex, Space, Text, Textarea, Title } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import QRCode from "qrcode";
import { useAuthContext } from "/@/shared/contexts/AuthContext";
import { io } from "socket.io-client";
import { getApiUrl } from "/@/services/api/api";
import LZString from "lz-string";

import {
  TOGGLE_FEATURE,
  DeleteS3FileDocument,
  GET_FILE_DOWNLOAD_URL,
  FileList,
} from "@jasper/shared";

type OrderGetHelpProps = {
  order: NonNullable<
    GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]
  >;
  closeModal?: () => void;
  refetch: () => void
  id: string;
};

export const OrderGetHelp: FC<OrderGetHelpProps> = ({
  order,
  closeModal = () => null,
  refetch,
  id,
}) => {
  const [files, setFiles] = useState<any[]>([]);
  const [filesToShow, setFilesToShow] = useState<any[]>([]);
  const { user } = useAuthContext();
  const [helpRequestMessage, setHelpRequestMessage] = React.useState("");
  const canvas = useRef(null);
  const [sendOrderHelpRequest, { error }] = useMutation(
    SendOrderHelpRequestDocument,
  );

  const [deleteS3File] = useMutation(DeleteS3FileDocument);
  const [getPresignedDownload] = useLazyQuery(
    GET_FILE_DOWNLOAD_URL,
  );

  const deleteOneS3File = async (key: string) => {
    return await deleteS3File({
      variables: {
        key: key,
      },
    });
  };

  const getFileUrlOrDownloadFile = async (key: string) => {
    const res = await getPresignedDownload({
      variables: {
        key: key,
      },
    });
    return res?.data?.getPresignedFileDownloadUrl;
  };

  if (TOGGLE_FEATURE.QR_HELP) {
    useEffect(() => {
      if (canvas.current) {
        const qrLink = `${window.location.origin}/#/order-help-upload-file/${id}/name/${order.patient?.lastName}/token/${user?.accessToken}`;
        console.log(qrLink);
        QRCode.toCanvas(
          canvas.current,
          qrLink.replace(/ /g, "%20"),
          function (error) {
            if (error) console.error("QR Code generation error:", error);
          },
        );
      }
    }, [canvas]);

    useEffect(() => {
      const socket = io(getApiUrl(),
        {
          query: {
            type: "helpFile",
            id: id,
          },
        });
      socket.on("clientOrderHelpFile", (data) => {
        setFiles(JSON.parse(LZString.decompress(data)));
      });
      socket.on("disconnect", (reason) => {
        console.info("disconnect", reason, user?.id);
        socket.connect();
      });

      return () => {
        socket.disconnect();
        socket.off("clientOrderFile");
      };
    }, []);
  }
  const onSubmit = () => {
    localStorage.removeItem("help_temp_ID");
    const args = {
      id: id,
      order: {
        connect: {
          id: order.id,
        },
      },
      comment: helpRequestMessage,
      type: CommentType.Help,
    };

    if (files.length > 0) {
      args.files = {
        create: [
          ...files,
        ],
      };
    }
    sendOrderHelpRequest({
      variables: {
        args: args,
      },
    }).then(() => {
      notifications.show({
        color: "green",
        title: "Votre demande a bien été envoyée",
        message: "",
      });
      refetch();
      closeModal();
    }).catch((error) => {
      console.error(error);
      notifications.show({
        color: "red",
        title: "Erreur lors de l'envoi de la demande",
        message: "",
      });
    });
  };

  useEffect(() => {
    if (files.length > 0) {
      const updateFilesToShow = async () => {
        const updatedFiles = await Promise.all(files.map(async (file) => ({
          ...file,
          url: await getFileUrlOrDownloadFile(file.key),
        })));
        setFilesToShow(updatedFiles);
      };
      updateFilesToShow();
    }
  }, [files]);

  return (
    <Box>
      <Flex
        w="100%"
        justify="right"
      >
        <IconX
          onClick={() => closeModal()}
          style={{ cursor: "pointer" }}
          color="black"
        />
      </Flex>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Title
          order={2}
          style={theme => ({ color: theme.colors.primary[5] })}
        >
          Demande d'aide pour la commande
        </Title>
        <Space h="md" />
        <Title
          order={2}
          style={theme => ({ color: theme.colors.primary[5] })}
        >{`${order.orderReference}`}</Title>
        <Space h="md" />
        <Title order={4}>
          Votre conseiller Jasper reviendra vers vous très rapidement.
        </Title>
        <Space h="lg" />
        {
          TOGGLE_FEATURE.QR_HELP && (
            <>
              <p style={{ textAlign: "center", margin: "0" }}>
                Ajoutez des photos<br />via ce QR Code
              </p>
              <canvas ref={canvas} style={{ margin: "auto", maxWidth:"200px", maxHeight:"200px" }} />
              {files && files.length > 0 && (
                <FileList files={filesToShow} deleteOneFile={deleteOneS3File} />
              )}
            </>
          )
        }
        <Textarea
          color="primary"
          placeholder="Veuillez indiquer la nature de votre demande d'aide"
          onChange={event => setHelpRequestMessage(event.target.value)}
          w="80%"
          autosize
          minRows={4}
          maxRows={12}
        />
        <Space h="md" />
        <Button onClick={onSubmit}>Envoyer</Button>

        {error && (
          <>
            <Space h="sm" />
            <Text
              size="sm"
              style={theme => ({ color: theme.colors.red[5] })}
            >
              Une erreur est survenue, veuillez contacter votre conseiller
              Jasper directement
            </Text>
          </>
        )}
      </Box>
      <Space h="md" />
    </Box>
  );
};
