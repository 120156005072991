import React from "react";
import {
  Indicator,
  Text,
  MantineProvider,
} from "@mantine/core";
import {
  Calendar,
} from "@mantine/dates";
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import {
  STYLE,
} from "../../types/constants";
import {
  isDayIncludeInBankHolidays,
} from "../../utils/order.utils";

interface CustomDateSelectionProps {
  selectDate: (date: Date) => void,
  optimalDeliveryDate: Date,
  minimalRushDeliveryDate: Date,
  selectedDate: Date | null
}

const CustomDateSelection = ({
  ...props
}: CustomDateSelectionProps) => {
  return (
    <MantineProvider>
      <div style={{ width: "min-content" }}>
      <Calendar
        hideOutsideDates={true}
        minDate={new Date(props.minimalRushDeliveryDate)}
        locale="fr"
        numberOfColumns={2}
        excludeDate={(date) =>{
          date.setHours(0,0,0,0);
          props.optimalDeliveryDate.setHours(0,0,0,0);
          return (
            (date.getDay() === 6 || date.getDay() === 0) // disable saturday and sunday as there is no deliveries on these days. days before minimum are also disabled
            || isDayIncludeInBankHolidays(date, props.bankHolidays)
          )
        }}
        defaultValue={props.optimalDeliveryDate.toString()}
        defaultChecked={true}
        renderDay={(date) => {
          const day = date.getDate();
          date.setHours(0,0,0,0);
          props.optimalDeliveryDate.setHours(0,0,0,0);
          return (
            <Indicator size={6} color="blue" offset={-2} disabled={date.getTime() !== props.optimalDeliveryDate.getTime()}>
              <div 
                style={
                  (props.selectedDate && date.getTime() === props.selectedDate.getTime()) || 
                  !props.selectedDate && date.getTime() === props.optimalDeliveryDate.getTime() ?
                    {backgroundColor:'blue', color: 'white', padding: '3px 5px', borderRadius:'5px'}:
                    {}
                }
              >
                {day}
              </div>
            </Indicator>
          );
        }}
        getDayProps={(date) => ({
          onClick: () => props.selectDate(date)
        })}
      />
      </div>
    </MantineProvider>
  );
}

export default CustomDateSelection;
