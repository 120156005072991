import React, { useState } from "react";
import {
  Stepper,
  Button,
  Group,
  Modal,
  Container,
  Box,
  Title,
  Space,
  Text,
} from "@mantine/core";
import {
  useForm,
} from "react-hook-form";

import {
  CreateProductFromScanOnly,
  OrderRecap,
  STYLE,
  getDeliveryDateAsString,
} from "@jasper/shared";

const ScanOnlyWorkflow = ({
  order,
  refetch,
  updateOrder,
  navigate,
  onSubmit,
  getDeliveryDate,
  user,
  saveOrderComment,
  updateDeliveryDate,
  refetchOrder,
  setIsShowAddProductModal,
}) => {
  const _NUMBER_OF_STEPS_ = 2;
  const rhf = useForm();

  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);
  const [estimatedDeliveryDateString, setEstimatedDeliveryDateString] = useState<string>(null);
  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 2 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  return (
    <>
      <Modal
        opened={isShowConfirmModal}
        onClose={() => {
          setIsShowConfirmModal(false);
          navigate("/");
        }}
        size="xl"
        withCloseButton={false}
        centered
        overlayProps={{
          opacity: 0.6,
          blur: 17,
        }}
      >
        <Container
          p="lg"
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Title
            order={2}
            style={theme=>({
              color: theme.colors.primary[3],
            })}
          >
            Commande passée avec succès !
          </Title>
          <Space h="lg" />
          <Title
            order={2}
            style={{ color: STYLE.secondary }}
          >
            {(estimatedDeliveryDateString && estimatedDeliveryDateString !== "")
              ? `Date de livraison estimée: ${estimatedDeliveryDateString ?? "Communiquée prochainement sur la page de la commande"}`
              : ""
            }
          </Title>
          <Space h="md" />
          <Text style={{textAlign: "center"}}>
            Vous pouvez suivre votre commande depuis votre dashboard, ou en cliquant ci-dessous
          </Text>
          <Space h="xl" />
          <Button
            onClick={onSubmit}
            data-testid="button-show-my-order"
          >
            Voir ma commande
          </Button>
        </Container>
      </Modal>
      <div style={{ padding: "1.5rem" }}>
        <Stepper active={active} onStepClick={setActive}>
          <Stepper.Step label="Informations">
            <CreateProductFromScanOnly
              refetch={refetch}
              rhf={rhf}
            />
          </Stepper.Step>
          <Stepper.Step label="Recap">
            <OrderRecap
              order={order}
              getDeliveryDate={getDeliveryDate}
              user={user}
              saveOrderComment={saveOrderComment}
              updateDeliveryDate={updateDeliveryDate}
              refetchOrder={refetchOrder}
              setIsShowAddProductModal={setIsShowAddProductModal}
            />
          </Stepper.Step>
        </Stepper>

        <Group justify="space-between" mt="xl">
          <div>
            {active !== 0 &&
              <Button variant="default" onClick={prevStep}>Précédent</Button>
            }
          </div>
          <Button
            onClick={async() => {
              if (active === (_NUMBER_OF_STEPS_ - 1)){
                const req = await getDeliveryDate({
                  variables: {
                    orderId: order?.id,
                  }
                });
                const deliveryDate = order?.deliveryDate ?? req.data?.getOrderEstimatedDeliveryDate?.deliveryDate;
                setEstimatedDeliveryDateString(getDeliveryDateAsString(deliveryDate));
                setIsShowConfirmModal(true);
              } else {
                rhf.handleSubmit(updateOrder)();
                nextStep();
              }
            }}
          >
            {active === (_NUMBER_OF_STEPS_ - 1) ? "Valider" : "Suivant"}
          </Button>
        </Group>
      </div>
    </>
  )
};

export default ScanOnlyWorkflow;
