import React, { useRef, useState, useEffect } from "react";
import {
  Title,
  Grid,
  Container,
  Box,
  Space,
  Flex,
  Textarea,
  Text,
  Button,
  Modal,
  LoadingOverlay,
  Loader,
} from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { IconCloudUpload } from "@tabler/icons-react";
import {
  notifications,
} from "@mantine/notifications";

import {
  STYLE,
  getPatientSexLabel,
  FileType,
  ItemsDescription,
  checkHasGenericImplant,
  getDeliveryDateAsString,
  CustomDateSelection,
} from "@jasper/shared";

const OrderRecap = ({
  order,
  refetchOrder,
  getDeliveryDate,
  user,
  saveOrderComment,
  updateDeliveryDate,
  setIsShowAddProductModal,
}) => {
  const [estimatedDeliveryDateString, setEstimatedDeliveryDateString] = useState<string>(null);
  const [deliveryDateObject, setDeliveryDateObject] = useState<string>({});
  const [isModalCustomDeliveryDateOpened, setIsModalCustomDeliveryDateOpened] = useState(false);
  const [newDeliveryDateSelected, setNewDeliveryDateSelected] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [orderComment, setOrderComment] = useState("");
  const [isLoading,setIsLoading] = useState(false);

  const patientBirthday = order.patient.birthdate
    ? new Date(order.patient.birthdate as string).toLocaleDateString("fr-FR")
    : 'Date de naissance non renseignée';

  const canvas = useRef(null);

  useEffect(() => {
    const computeDeliveryDate = async() => {
      const req = await getDeliveryDate({
        variables: {
          orderId: order?.id,
        }
      });
      setDeliveryDateObject(req.data?.getOrderEstimatedDeliveryDate);
      setEstimatedDeliveryDateString(getDeliveryDateAsString(req.data?.getOrderEstimatedDeliveryDate?.deliveryDate));
    };
    computeDeliveryDate();
  }, [false]);
  
  const onSubmitUpdateDeliveryDate = async (newDeliveryDate) => {
    setIsLoading(true);
    const toSaveDeliveryDate = new Date(newDeliveryDate);
    const toSaveDeliveryDateUTC = new Date(newDeliveryDate);
    if (toSaveDeliveryDate){
      toSaveDeliveryDate.setHours(12);
      toSaveDeliveryDateUTC.setDate(toSaveDeliveryDateUTC.getDate() - 1); // Webapp and Shared are in differents timezone
    }
    const req = await updateDeliveryDate({
      variables: {
        orderId: order.id,
        newDeliveryDate: toSaveDeliveryDateUTC,
        shouldUpdateAbutmentType: false,
      },
    });
    setIsModalCustomDeliveryDateOpened(false);
    if (req?.data?.updateOrderDeliveryDate) {
      await refetchOrder();
      setEstimatedDeliveryDateString(getDeliveryDateAsString(new Date(toSaveDeliveryDate)));
      notifications.show({
        color: "green",
        title: "Date de livraison modifiée",
      });
      setNewDeliveryDateSelected(newDeliveryDate);
      setIsLoading(false);
    } else {
      notifications.show({
        color: "red",
        title: "Erreur lors de la demande de modification",
      });
      setIsLoading(false);
    }
  };

  if (isLoading){
    return (
      <LoadingOverlay visible={true} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} >
        <Loader />
      </LoadingOverlay>
    )
  }

  return (
    <>
      <Modal opened={isModalCustomDeliveryDateOpened} size="auto" onClose={() => setIsModalCustomDeliveryDateOpened(false)}>
        <CustomDateSelection
          selectDate={(newDeliveryDate) => onSubmitUpdateDeliveryDate(newDeliveryDate)}
          optimalDeliveryDate={new Date(deliveryDateObject?.deliveryDate)}
          bankHolidays={deliveryDateObject?.bankHolidays}
          minimalRushDeliveryDate={deliveryDateObject?.minimalRushDeliveryDate}
          selectedDate={newDeliveryDateSelected}
        />
      </Modal>
      <Title style={{ paddingBottom: "0.5rem" }}>Complétez et validez votre commande</Title>
      <Title
        order={3}
        style={{ color: STYLE.primary, paddingBottom: "1rem" }}
      >
        Date de livraison estimée : {
          (estimatedDeliveryDateString)?.toLocaleString("fr-FR", {
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "long",
          }) ?? "En cours de calcul. Elle vous sera communiquée prochainement"}
        {user?.customDeliveryDateEnabled &&
          <Button
            style={{ marginLeft: "2rem" }}
            onClick={async() => {
              await saveOrderComment();
              setIsModalCustomDeliveryDateOpened(true);
            }}
          >
            Changer la date de livraison
          </Button>
        }
      </Title>
      <Grid gutter="md">
        <Grid.Col span={6}>
          <Container
            p="xl"
            style={theme => ({
              borderRadius: "10px",
              backgroundColor: "#f3f6fe",
              height: "100%",
            })}
          >
            <Grid gutter="md">
              <Grid.Col span={6}>
                <Box>
                  <Title order={4}>Praticien</Title>
                  <Text>
                    {order.user.firstName} {order.user.lastName}
                  </Text>
                  <Text>
                    {order.address.streetNumber} {order.address.streetName}{" "}
                  </Text>
                  <Text>
                    {order.address.city} {order.address.postcode}
                  </Text>
                </Box>
                <Space h="xl" />
                <Box>
                  <Title order={4}>Patient</Title>
                  <Text>
                    {order.patient.firstName} {order.patient.lastName}
                  </Text>
                  <Text>{patientBirthday}</Text>
                  <Text>{getPatientSexLabel(order.patient.sex)}</Text>
                </Box>
              </Grid.Col>
              <Grid.Col span={6} style={{ margin: "auto 0", textAlign: "center", width: '100%' }}>
                <div style={{width: "fit-content",float: "right", marginBottom: "10px"}}>
                  <p style={{ textAlign: "center", margin: "0" }}>
                    Ajoutez des photos<br/>via ce QR Code
                  </p>
                  <canvas style={{ maxWidth: "170px", maxHeight: "170px", margin: "auto" }} ref={canvas} />
                  <p style={{ textAlign: "center", margin: "5px" }}>OU</p>
                  <Dropzone
                    accept={{
                      "image/*": [],
                      "application/dicom": [".DCM", ".dcm"],
                      "model/stl": [".stl",".STL"],
                    }}
                    onDrop={async(files) => {
                      await uploadFileToS3([...files], FileType.ORDER_FILE);
                      refetch();
                    }}
                    style={{
                      border: '2px dashed gray',
                      borderRadius: '5px',
                      padding: '5px',
                      cursor: 'pointer'
                    }}
                  >
                    <Flex
                      justify="center"
                      align="center"
                    >
                      <IconCloudUpload />
                      <Box ml="sm">
                        <span
                          size="sm"
                          style={{fontWeight: 'bold', textWrap: 'wrap'}}
                        >
                          {" "}
                          {"Ajouter un fichier à votre commande"}
                        </span>
                      </Box>
                    </Flex>
                  </Dropzone>
                </div>
              </Grid.Col>
            </Grid>
            <Box>
              {imageList && imageList.length > 0 && (
                <Box mt={10} mb={10}>
                  <FileList
                    files={[...imageList
                      .filter(
                        file =>
                          !file.key.includes(".3OXZ") &&
                          !file.key.includes(
                            "-traçabilité-praticien.pdf",
                          ) &&
                          !file.key.includes("-traçabilité-patient.pdf"),
                      ),
                    ]}
                    deleteOneFile={deleteOneFile}
                    downloadOneFile={getPresignedDownloadUrl}
                  />
                </Box>
              )}
              <Textarea
                value={orderComment}
                color="primary"
                placeholder="Un commentaire sur la commande ? (livraison, délais, etc.)"
                onChange={(event) =>
                  setOrderComment(event.currentTarget.value)
                }
              />
            </Box>
            <Box>
            </Box>
          </Container>
        </Grid.Col>
        <Grid.Col span={6}>
          <Container
            p="xl"
            style={theme => ({
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              borderRadius: "10px",
              backgroundColor: "#f3f6fe",
              height: "100%",
            })}
          >
            <Box style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <div>
              {(order?.products ?? []).map(product => (
                <ItemsDescription
                  key={product.id}
                  product={product}
                />
              ))}
              </div>
              {checkHasGenericImplant(order) &&
                <div>
                  <Text style={{ color: STYLE.primary, fontWeight: "bold" }}>
                    En choisissant des délais de fabrication raccourcis,
                  </Text>
                  <Text style={{ marginBottom: "1rem", color: STYLE.primary, fontWeight: "bold" }}>
                    vous acceptez de remplacer le pilier original fabriquant par un pilier personnalisé.
                  </Text>
                </div>
              }
            </Box>
            <Button
              h={60}
              variant="white"
              color={STYLE.primary}
              onClick={async () => {
                await saveOrderComment();
                setIsShowAddProductModal(true);
              }}
            >
              {"Ajouter un produit (ex: gouttière, ...)"}
            </Button>
          </Container>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default OrderRecap;
