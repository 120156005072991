import {Maybe} from "graphql/jsutils/Maybe";
import {
  AbutmentType,
  BridgeType,
  GetAllImplantReferencesQuery,
  GetImplantItemChoicesQuery,
  GetOrderByUniqueAttributeQuery,
  ItemType,
  RetentionType,
  WorkflowType,
} from "/@/generated/graphql";
import {
  ProductGenericType,
} from "/@/shared/types/products/products.types";

import {
  getItemTypeLabel,
  isAnatomyItemType,
  isImplantItemType,
  isRemovableItemType,
  getProductTeeth,
} from "@jasper/shared";

export const getProductItem = (product: ProductGenericType): any => {
  if (isAnatomyItemType(product.productType) && product.anatomyItem) {
    return product.anatomyItem[0];
  } else if (isImplantItemType(product.productType) && product.implantItem) {
    return product.implantItem[0];
  } else if (
    isRemovableItemType(product.productType) &&
    product.removableItem
  ) {
    return product.removableItem[0];
  }
  return null;
};
export const getItemTypeLabelPlural = (
  productType: ItemType,
  teeth: number[],
) => {
  const plural = teeth.length > 1 ? true : false;
  if (
    (!plural && productType !== ItemType.Implant) ||
    isRemovableItemType(productType) ||
    productType === ItemType.Bridge ||
    productType === ItemType.BridgeImplant
  ) {
    return getItemTypeLabel(productType);
  }
  if (productType === ItemType.Crown) {
    return "Couronnes";
  } else if (productType === ItemType.Inlay) {
    return "Inlays";
  } else if (productType === ItemType.Implant) {
    return "Couronnes sur implant";
  } else if (productType === ItemType.SplintedCrownImplant) {
    return "Couronnes solidarisées sur implant";
  } else if (productType === ItemType.Onlay) {
    return "Onlays";
  } else if (productType === ItemType.Lay) {
    return "Inlays/Onlays";
  } else if (productType === ItemType.Veneer) {
    return "Facettes";
  } else if (productType === ItemType.InlayCore) {
    return "Inlay-cores";
  } else if (productType === ItemType.PostAndCoreRetainedCrown) {
    return "Couronnes Richmond";
  }
};

export const OrderFormItemTypeChoices = Object.values(ItemType)
  .filter(
    type =>
      type !== ItemType.Inlay &&
      type !== ItemType.Onlay &&
      type !== ItemType.Overlay &&
      type !== ItemType.BleachingTray &&
      type !== ItemType.Retainer &&
      type !== ItemType.NightGuard &&
      type !== ItemType.FullDentureWaxBite &&
      type !== ItemType.ImmediateDenture &&
      type !== ItemType.CustomAbutment &&
      type !== ItemType.Model &&
      type !== ItemType.ImplantCrown &&
      type !== ItemType.Implant &&
      type !== ItemType.BridgePontic &&
      type !== ItemType.PostAndCoreRetainedCrown &&
      type !== ItemType.SplintedCrown &&
      type !== ItemType.SpaceMaintainer &&
      type !== ItemType.RetentionWire &&
      type !== ItemType.WaxUp &&
      type !== ItemType.AdditionalImpressionForRemovableProsthesis,
  )
  .map(type => ({
    value: type,
    label: getItemTypeLabel(type),
  }));

export const AddProdctToOrderFormItemTypeChoices = Object.values(ItemType)
  .filter(
    type =>
      type !== ItemType.Inlay &&
      type !== ItemType.Onlay &&
      type !== ItemType.Overlay &&
      type !== ItemType.BleachingTray &&
      type !== ItemType.Retainer &&
      type !== ItemType.NightGuard &&
      type !== ItemType.FullDentureWaxBite &&
      type !== ItemType.ImmediateDenture &&
      type !== ItemType.Abutment &&
      type !== ItemType.CustomAbutment &&
      type !== ItemType.Model &&
      type !== ItemType.ImplantCrown &&
      type !== ItemType.Overlay &&
      type !== ItemType.RichmondCrown &&
      type !== ItemType.SplintedCrown &&
      type !== ItemType.BridgePontic,
  )
  .map(type => ({
    value: type,
    label: getItemTypeLabel(type),
  }));

export const getTeethLabelRaw = (teeth: number[]): string => {
  let teethLabel = "";
  if (teeth.length <= 0) {
    return "";
  }
  teeth.forEach(tooth => {
    if (!isNaN(tooth)) {
      teethLabel += tooth + " ";
    }
  });
  if (teethLabel) {
    teethLabel = teethLabel.slice(0, -1);
  }
  return teethLabel;
};

export const getProductTeethLabels = (product: ProductGenericType): string => {
  const teeth = getProductTeeth(product);
  const isWithArch = isRemovableItemType(product.productType);
  let teethLabel = "";
  if (teeth.length <= 0) {
    return "non définies";
  }
  if (isWithArch === false) {
    teethLabel = teeth.length > 1 ? "dents " : "dent ";

    teeth.forEach(tooth => {
      if (!isNaN(tooth)) {
        teethLabel += tooth + " ";
      }
    });
    if (teethLabel) {
      teethLabel = teethLabel.slice(0, -1);
    }
  } else {
    if (teeth.includes(11) && teeth.includes(31)) {
      return "Bimaxillaire";
    } else if (teeth.includes(11)) {
      return "Arcade maxiliaire";
    } else if (teeth.includes(31)) {
      return "Arcade mandibulaire";
    }
  }
  return teethLabel;
};

export const getProductShade = (
  product: ProductGenericType,
  removableTeeth = false,
  removableGingiva = false,
): string => {
  if (
    isAnatomyItemType(product.productType) &&
    product.anatomyItem &&
    product.anatomyItem[0].itemShade
  ) {
    return product.anatomyItem[0].itemShade.label;
  } else if (
    isImplantItemType(product.productType) &&
    product.implantItem &&
    product.implantItem[0].itemShade
  ) {
    return product.implantItem[0].itemShade.label;
  } else if (
    isRemovableItemType(product.productType) &&
    product.removableItem &&
    product.removableItem[0]
  ) {
    if (removableTeeth && product.removableItem[0].teethShade) {
      return `Teinte dents ${product.removableItem[0].teethShade.label}`;
    } else if (removableTeeth && !product.removableItem[0].teethShade) {
      return "";
    }
    if (removableGingiva && product.removableItem[0].gingivaShade) {
      return `Teinte gencives ${product.removableItem[0].gingivaShade.label}`;
    } else if (removableGingiva && !product.removableItem[0].gingivaShade) {
      return "";
    }
  }
  return "";
};

export const getBridgeTypeLabel = (product: ProductGenericType) => {
  if (product.productType === ItemType.Bridge && product?.anatomyItem) {
    //console.log(product.anatomyItem[0].bridgeType);
    if (product.anatomyItem[0].bridgeType === BridgeType.Conventional) {
      return "Conventionnel";
    } else if (product.anatomyItem[0].bridgeType === BridgeType.Cantilever) {
      return "Cantilever";
    } else if (product.anatomyItem[0].bridgeType === BridgeType.Maryland) {
      return "Maryland";
    }
  }
  //console.log("null return bridgetype");
  return "";
};

export const getProductsOrderedCountLabel = (
  order: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"],
): string => {
  if (!order.products || order.products.length <= 0) {
    return "";
  }
  let productsOrderedCountLabel = "";
  for (let i = 0; i < order.products.length; i++) {
    const product = order.products[i];
    if (product) {
      if (product.productType === ItemType.InlayCore && order.products.length === 1){
        return (product?.anatomyItem ?? []).length > 1
          ? "Multiples produits"
          : `${(product?.anatomyItem ?? []).length} Inlay-core`;
      } else {
        productsOrderedCountLabel += getProductOrderedCountLabel(product)
          ? `${getProductOrderedCountLabel(product)?.slice(2)}, `
          : "";
      }
    }
  }
  return productsOrderedCountLabel.slice(0, -2);
};

export const getProductOrderedCountLabel = (product: ProductGenericType) => {
  if (product === undefined || product.productType === undefined) {
    return "";
  }
  let teethLength =
    product.productType === ItemType.BridgeImplant
      ? 1
      : getProductTeeth(product).length;
  if (
    isAnatomyItemType(product.productType) &&
    product.productType !== ItemType.Bridge &&
    product.anatomyItem
  ) {
    teethLength =
      product.anatomyItem.length > 1 ? product.anatomyItem.length : teethLength;
    return `- ${teethLength} x ${getItemTypeLabelPlural(
      product.productType,
      getProductTeeth(product),
    )}`;
  } else if (product.productType === ItemType.Bridge) {
    const bridgeType = getBridgeTypeLabel(
      product,
    );
    return `- 1 x ${getItemTypeLabel(product.productType)}${bridgeType ? " " + bridgeType : ""}`;
  } else if (isImplantItemType(product.productType)) {
    return `- ${teethLength} x ${getItemTypeLabelPlural(
      product.productType,
      getProductTeeth(product),
    )}`;
  } else if (isRemovableItemType(product.productType)) {
    return `- ${getItemTypeLabel(
      product.productType,
      getProductItem(product)?.productSpecificType ?? "",
    )}`;
  }
};

export const getProductMaterial = (product: ProductGenericType) => {
  if (
    isAnatomyItemType(product.productType) &&
    product?.anatomyItem?.["0"].itemMaterial
  ) {
    return product.anatomyItem[0].itemMaterial.label;
  }
};

export const getProductMaterialLabel = (
  product: ProductGenericType,
): string => {
  if (isAnatomyItemType(product.productType) && product.anatomyItem) {
    return product.anatomyItem[0].itemMaterial?.label ?? "";
  } else if (isImplantItemType(product.productType) && product.implantItem) {
    return product.implantItem[0].itemMaterial?.label ?? "";
  } else if (
    isRemovableItemType(product.productType) &&
    product.removableItem
  ) {
    return product.removableItem[0].itemMaterial?.label ?? "";
  }
  return "";
};

export const getAnatomyItemLabel = (
  anatomyItem: NonNullable<ProductGenericType["anatomyItem"]>[0],
) => {
  return anatomyItem.itemMaterial?.label ?? "";
};

export const filterShadeByPreferences = (
  shadeChoices: any,
  accountProductPreferences: any,
  productPreferenceType: any,
) => {
  if (
    (accountProductPreferences?.getAccountProductPreferences ?? []).length === 0
  ) {
    return shadeChoices;
  }
  if (!productPreferenceType) {
    return shadeChoices;
  }
  const userPreferences =
    accountProductPreferences?.getAccountProductPreferences.find(
      (pref: {productPreferenceType: string}) =>
        pref.productPreferenceType === productPreferenceType,
    );
  if (!userPreferences) {
    return shadeChoices;
  }
  const prefrencesOfShade = userPreferences.shadeType;
  if (!prefrencesOfShade) {
    return shadeChoices;
  }
  return shadeChoices.filter(
    (shade: any) => shade?.shadeType == prefrencesOfShade,
  );
};

export const getImplantReferenceBrands = (data: GetImplantItemChoicesQuery) => {
  if (data.getAllImplantReferences && data.getAllImplantReferences.length > 0) {
    return data.getAllImplantReferences
      .map(implantReference => implantReference.brand.trim())
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => a.localeCompare(b));
  }
  return [];
};

export const getImplantReferenceChoices = (
  data: any,
): {
  value: string;
  label: string;
  brand: string;
  platforms: string[];
}[] => {
  if (data.getAllImplantReferences && data.getAllImplantReferences.length > 0) {
    return data.getAllImplantReferences.map((implantReference: any) => ({
      value: implantReference.id,
      ...implantReference,
    }));
    // .sort(
    //   (
    //     a: {
    //       value: string;
    //       label: string;
    //       brand: string;
    //       platforms: string[];
    //     },
    //     b: {
    //       value: string;
    //       label: string;
    //       brand: string;
    //       platforms: string[];
    //     },
    //   ) => a.value.localeCompare(b.value),
    // );
  }
  return [];
};

export const getImplantItemPlatformChoices = (data: string[]) => {
  if (data) {
    return data.map((item: string) => {
      return {label: item, value: item};
    });
  }
  return [];
};

export const getImplantItemConnectionChoices = (
  data: {value: string; label: string}[],
) => {
  if (data && data.length > 0) {
    return data.map((item: {value: string; label: string}) => {
      return {label: item.label, value: item.value};
    });
  }
  return [];
};

export const getAbutmentTypeLabel = (
  abutmentType: Maybe<AbutmentType>,
): string => {
  if (abutmentType === AbutmentType.Standard) {
    return "standard";
  } else if (abutmentType === AbutmentType.Custom) {
    return "personnalisé";
  } else if (abutmentType === AbutmentType.StandardGeneric) {
    return "standard generique";
  }
  return "non défini";
};

export const getRetentionTypeLabel = (
  retentionType: Maybe<RetentionType>,
): string => {
  if (retentionType === RetentionType.CementRetained) {
    return "scellé";
  } else if (retentionType === RetentionType.ScrewRetained) {
    return "transvissé";
  }
  return "";
};

// export const getAbutmentMaterialLabel = (
//   abutmentMaterial: Maybe<AbutmentMaterial>,
// ): string => {
//   if (abutmentMaterial === AbutmentMaterial.CustomTitanium) {
//     return "personnalisé en titane";
//   } else if (
//     abutmentMaterial === AbutmentMaterial.CustomZirconiaTitaniumHybrid
//   ) {
//     return "personnalisé hybride zirconia/titane";
//   }
//   return "";
// };

export const getImplantItemAbutmentDetails = (product: ProductGenericType) => {
  if (product.implantItem && product.implantItem.length > 0) {
    const implant = product.implantItem.find(
      item => item?.itemType === ItemType.Implant,
    );
    if (!implant) {
      return "";
    }
    if (implant.abutmentType === AbutmentType.Custom) {
      return "Pilier:";
      // ${getRetentionTypeLabel(
      //   implant.retentionType,
      // )} ${getAbutmentMaterialLabel(implant.abutmentMaterial)}`;
    } else if (implant.abutmentType === AbutmentType.Standard) {
      return `Pilier ${getAbutmentTypeLabel(
        implant.abutmentType,
      )} ${getRetentionTypeLabel(implant.retentionType)}`;
    }
    return "";
  }
};

export const getImplantItemImplantReferenceDetails = (
  product: ProductGenericType,
  getAllImplantReferencesData: GetAllImplantReferencesQuery | undefined,
) => {
  if (!product || !getAllImplantReferencesData) {
    return "";
  }
  if (product.implantItem && product.implantItem.length > 0) {
    if (getAllImplantReferencesData) {
      const implant = product.implantItem[0];
      if (
        implant.implantReferenceSuggestion?.length &&
        implant.implantReferenceSuggestion.length > 0
      ) {
        return "Référence d'implant donnée par le praticien";
      } else if (implant.implantReference) {
        return `Implant ${implant.implantReference.brand} ${implant.implantReference.label} ${implant.implantPlatform}`;
      } else {
        return "";
      }
    }
  }
  return "";
};

export const getPartialDentureWorkflowShortLabel = (
  product: ProductGenericType,
) => {
  if (product && product.removableItem && product.removableItem.length > 0) {
    const item = product.removableItem[0];
    if (item.workflowType === WorkflowType.OneAppointmentDirect) {
      return "1 RDV";
    } else if (item.workflowType === WorkflowType.WaxRim) {
      return "2 RDV - Avec cire d'occlusion";
    } else if (item.workflowType === WorkflowType.WithFitting) {
      return "2 RDV - Avec essayage";
    }
  }
  return "";
};

export const isUpperArchTeeth = (teeth: number) => {
  return (teeth >= 11 && teeth <= 18) || (teeth >= 21 && teeth <= 28);
};

export const isLowerArchTeeth = (teeth: number) => {
  return (teeth >= 31 && teeth <= 38) || (teeth >= 41 && teeth <= 48);
};

export const getTeethsFromUpperArch = (teeths: number[]): number[] => {
  if (!teeths || teeths.length === 0) {
    return [];
  }
  return teeths.reduce((acc: number[], teeth: number) => {
    if (teeth <= 28) {
      acc.push(teeth);
    }
    return acc;
  }, []);
};

export const getTeethsFromLowerArch = (teeths: number[]): number[] => {
  if (!teeths || teeths.length === 0) {
    return [];
  }
  return teeths.reduce((acc: number[], teeth: number) => {
    if (teeth >= 31) {
      acc.push(teeth);
    }
    return acc;
  }, []);
};

export const getAllUpperTeeths = (): number[] => {
  const arr = [];
  for (let i = 11; i <= 18; i++) {
    arr.push(i);
  }
  for (let i = 21; i <= 28; i++) {
    arr.push(i);
  }
  return arr;
};

export const getAllLowerTeeths = (): number[] => {
  const arr = [];
  for (let i = 31; i <= 38; i++) {
    arr.push(i);
  }
  for (let i = 41; i <= 48; i++) {
    arr.push(i);
  }
  return arr;
};
