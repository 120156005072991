import React, {FC} from "react";
import {useFiles} from "@jasper/shared";
import {
  Box,
  Container,
  Flex,
  Space,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import {
  FileType,
  GetManyOrdersOfUserQuery,
  GetOrderByUniqueAttributeQuery,
} from "/@/generated/graphql";
import {IconCloudUpload, IconX} from "@tabler/icons-react";
import {Dropzone} from "@mantine/dropzone";

type OrderFilesManagementProps = {
  order:
    | GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]
    | GetManyOrdersOfUserQuery["getManyOrdersOfUser"][0];
  closeModal?: () => void;
};

export const OrderFilesManagement: FC<OrderFilesManagementProps> = ({
  order,
  closeModal = () => null,
}) => {
  const {getPresignedDownloadUrl, uploadFileToS3, filesOfOrder} = useFiles(
    order.id,
  );

  const theme = useMantineTheme();

  const fileFilter = (files) => {
    return files.filter(file => {
      if (
        file.fileType !== FileType.BillingDentistTraceability &&
        file.fileType !== FileType.BillingPatientTraceability &&
        file.fileType !== FileType.ClientFile
      ) {
        return false;
      }
      return true;
    });
  };




  return (
    <Box
      style={() => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      })}
    >
      <Flex
        w="100%"
        justify="right"
      >
        <IconX
          onClick={() => closeModal()}
          style={{cursor: "pointer"}}
          color={theme.colors.red[5]}
        />
      </Flex>
      <Title
        order={2}
        style={theme=>({color: theme.colors.primary[5]})}
      >
        Fichiers de la commande {order.orderReference}
      </Title>
      <Space h="sm" />
      <Flex
        direction="row"
        justify="center"
        align="center"
        w="30%"
        // style={theme => ({backgroundColor: theme.colors.primaryLight[8]})}
      >
        <Dropzone
          onDrop={files => uploadFileToS3(files)}
          onReject={files => console.log("rejected files", files)}
          maxSize={3 * 1024 ** 2}
          style={() => ({
            height: "fit-content",
            width: "fit-content",
            border: 0,
          })}
        >
          <Flex
            justify="center"
            align="center"
          >
            <IconCloudUpload />
            <Box ml="sm">
              <Text
                size="sm"
                style={{fontWeight: "bold"}}
              >
                {" "}
                {"Ajoutez des fichiers à votre commande"}
              </Text>
            </Box>
          </Flex>
        </Dropzone>
        {/* <FileButton
          multiple
          onChange={onChangeFileButton}
        >
          {props => (
            <Button
              variant="filled"
              {...props}
            >
              Ajoutez un fichier
            </Button>
          )}
        </FileButton> */}
      </Flex>
      <Space h="md" />
      <Container
        p="md"
        style={theme => ({
          backgroundColor: theme.colors.primaryLight[9],
          minHeight: "30%",
          minWidth: "50%",
          maxWidth: "fit-content",
          borderRadius: theme.radius.md,
        })}
      >
        {!filesOfOrder?.getFilesOfOrder ||
          fileFilter(filesOfOrder.getFilesOfOrder).length === 0 ? (
          <Title
            order={3}
            style={{fontWeight: "normal", textAlign: "center"}}
          >
            Aucun fichier pour cette commande
          </Title>
        ) : (
          <Box>
            {fileFilter(filesOfOrder.getFilesOfOrder)
              .map(file => {
                const fileName = file.key.includes("traçabilité")
                  ? file.key
                      .substring(37)
                      .replace(order.orderReference, "fiche")
                  : file.key.substring(37);

                return (
                  <Box
                    key={file.id}
                    style={{display: "flex"}}
                  >
                    <Text
                      onClick={() => getPresignedDownloadUrl(file.key)}
                      style={{cursor: "pointer", width: "max-content"}}
                    >
                      {fileName}
                    </Text>
                  </Box>
                );
              })}
          </Box>
        )}
      </Container>
      <Space h="md" />
    </Box>
  );
};
