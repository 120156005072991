import React from "react";

import {
  SelectorButtonRef,
  WaxUpType,
  ArchTypeEn,
  ItemType,
} from "@jasper/shared";

const CreateProductFromScanOnly = ({ rhf }) => {
  const watchScanOnlyType = rhf.watch("scanOnlyType");
  const watchArchType = rhf.watch("archType");
  const watchWaxUpType = rhf.watch("waxUpType");

  return (
    <>
      <SelectorButtonRef
        label="Veuillez choisir un type de produit"
        data={[
          {
            label: "Mainteneur d'espace",
            value: ItemType.SPACE_MAINTAINER,
          },
          {
            label: "Fil de contention",
            value: ItemType.RETENTION_WIRE,
          },
          {
            label: "Wax up",
            value: ItemType.WAX_UP,
          },
          {
            label: "Empreinte supplémentaire pour prothèse amovible",
            value: ItemType.ADDITIONAL_IMPRESSION_FOR_REMOVABLE_PROSTHESIS,
          }
        ]}
        name="scanOnlyType"
        onSubmit={(selectedValue) => rhf.setValue("scanOnlyType", selectedValue)}
        control={rhf.control}
        errors={rhf.errors}
        watch={watchScanOnlyType}
        required={true}
      />
      {watchScanOnlyType === ItemType.RETENTION_WIRE &&
        <SelectorButtonRef
          label="Arcade"
          data={[
            {
              label: "Maxillaire",
              value: ArchTypeEn.UPPER_ARCH,
            },
            {
              label: "Mandibulaire",
              value: ArchTypeEn.LOWER_ARCH,
            },
            {
              label: "Bimaxillaire",
              value: ArchTypeEn.BOTH_ARCH,
            }
          ]}
          name="archType"
          onSubmit={(selectedValue) => rhf.setValue("archType", selectedValue)}
          control={rhf.control}
          errors={rhf.errors}
          watch={watchArchType}
          required={true}
        />
      }
      {watchScanOnlyType === ItemType.WAX_UP &&
        <SelectorButtonRef
          label="Type de wax up"
          data={[
            {
              label: "Numerique",
              value: WaxUpType.NUMERICAL,
            },
            {
              label: "Physique",
              value: WaxUpType.PHYSICAL,
            },
          ]}
          name="waxUpType"
          onSubmit={(selectedValue) => rhf.setValue("waxUpType", selectedValue)}
          control={rhf.control}
          errors={rhf.errors}
          watch={watchWaxUpType}
          required={true}
        />
      }
    </>
  )
};

export default CreateProductFromScanOnly;
